import isEmail from "validator/lib/isEmail";

export const isBlank = str => !str || /^\s*$/.test(str);
export const isNotBlank = str => !isBlank(str);
export const isValidEmail = email => isNotBlank(email) && isEmail(email);
export const isInvalidEmail = email => !isValidEmail(email);

export const validateEmailRegistrationForm = (intl, formData) => {
  const errors = {};
  const errorMessages = [];

  const addError = (key, msg) => {
    errors[key] = true;
    errorMessages.push(msg);
  };

  let key = "email";
  if (isInvalidEmail(formData[key])) {
    addError(
      key,
      intl.formatMessage({
        id: "subscribe-modal.invalid-email",
      })
    );
  }

  return {
    isValid: errorMessages.length === 0,
    errors: errorMessages.length > 0 && errors,
    errorMessages: errorMessages.length > 0 && errorMessages,
  };
};
