import React, { Fragment, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Transition } from "@headlessui/react";
import OutsideClickHandler from "react-outside-click-handler";
import { Link, navigate } from "gatsby";
import useForm from "../hooks/use-form";
import { validateEmailRegistrationForm as validateForm } from "../../utils/form-validation";
import { submitEmailRegistrationForm } from "../../utils/form-actions";
import FormError from "../form-error";
import Button from "../button";

const Modal = ({ isOpen = false, onClose, children }) => (
  <Transition
    key="background"
    show={isOpen}
    enter="ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
    as="div"
  >
    <div className={`fixed z-10 overflow-y-auto inset-0`}>
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        <OutsideClickHandler disabled={!isOpen} display="inline" onOutsideClick={() => onClose()}>
          <Transition
            key="modal"
            show={isOpen}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            as="div"
            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            {children}
          </Transition>
        </OutsideClickHandler>
      </div>
    </div>
  </Transition>
);

const SubscribeModal = ({ isOpen = false, to, onClose }) => {
  const intl = useIntl();

  const [formData, onInputChange, onSubmit] = useForm({
    validateForm: validateForm.bind(this, intl),
    initialValues: { email: "" },
    onResponse: () => navigate(to),
  });

  const isError = formData.errorMessages && formData.errorMessages.length > 0;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div>
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-pink-100">
          <svg
            className="w-6 h-6 text-pink-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
            <FormattedMessage id="subscribe-modal.title" />
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              <FormattedMessage id="subscribe-modal.text" />
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <form className="sm:flex sm:max-w-md flex-col" id="subscriptionForm" onSubmit={(e) => onSubmit(e, submitEmailRegistrationForm)}>
          <label htmlFor="email" className="sr-only">
            <FormattedMessage id="footer.subscribe-newsletter.email" />
          </label>
          <input
            autoFocus
            type="email"
            id="email"
            value={formData.email}
            required
            className={`min-w-0 w-full py-2 px-4 sm:text-sm   appearance-none  bg-white border border-transparent rounded-md text-base leading-6 text-gray-900 placeholder-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue focus:placeholder-gray-400 transition duration-150 ease-in-out ${
              formData.errors && formData.errors.email ? " border-pink-600" : "border-gray-300"
            }`}
            placeholder={intl.formatMessage({
              id: "footer.subscribe-newsletter.enter-email",
            })}
            onChange={onInputChange}
          />
          <FormError title="" list={formData.errorMessages || []} />
        </form>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <Button
            type="submit"
            color="pink"
            loading={formData.loading}
            onClick={(e) => onSubmit(e, submitEmailRegistrationForm)}
            className="w-full  text-base font-medium sm:col-start-2 sm:text-sm"
          >
            <FormattedMessage id="download.download" />
          </Button>
          <Link
            to={to || "#"}
            rel="nofollow"
            className="mt-3 w-full inline-flex justify-center px-4 py-2 bg-white text-base font-medium text-gray-400 hover:text-gray-700 sm:mt-0 sm:col-start-1 sm:text-sm"
          >
            <FormattedMessage id="subscribe-modal.skip" />
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default SubscribeModal;
