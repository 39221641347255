import React, { Fragment, useState } from "react";
import Layout from "../components/layout";
import { FormattedMessage } from "react-intl";
import { graphql, Link } from "gatsby";
import { Transition } from "@headlessui/react";
import { FormattedDate } from "../components/date";
import OutsideClickHandler from "react-outside-click-handler";
import SubscribeModal from "../components/newsletter/subscribe-modal";
import Button from "../components/button";

const MacOsVersions = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <OutsideClickHandler
        disabled={!isOpen}
        display="inline"
        onOutsideClick={() => setIsOpen(false)}
      >
        <button
          type="button"
          className="group bg-white rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>Supported OS</span>

          <svg
            className={`w-6 h-6 ${isOpen ? "text-gray-600" : "text-gray-400"}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
        <Transition
          key="menu"
          show={isOpen}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <div className="absolute left-0 transform translate-x-1/2 mt-4 px-2 w-screen max-w-xs sm:px-0 text-left">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                <div className="-m-3 p-3 block rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                  <p className="text-base font-medium text-gray-900">
                    macOS Monterey (12)
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    Intel Silicon, Apple Silicon
                  </p>
                </div>
                <div className="-m-3 p-3 block rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                  <p className="text-base font-medium text-gray-900">
                    macOS Big Sur (11)
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                  Intel Silicon, Apple Silicon
                  </p>
                </div>
                <div className="-m-3 p-3 block rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                  <p className="text-base font-medium text-gray-900">
                    macOS Catalina (10.15)
                  </p>
                </div>

                <div className="-m-3 p-3 block rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                  <p className="text-base font-medium text-gray-900">
                    macOS Mojave (10.14)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </OutsideClickHandler>
    </>
  );
};

const DownloadPage = ({ pageContext: { language, title, id }, data }) => {
  const [isModal, showModal] = useState(false);
  return (
    <Layout locale={language} title={title} pageId={id}>
      <div className="bg-white ">
        <div className="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-base leading-6 font-semibold text-pink-600 tracking-wide uppercase">
              <FormattedMessage id="download.title" />
            </h1>
            <p className="mt-1 text-4xl leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
              <FormattedMessage id="download.pre-title" />
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl leading-7 text-gray-500">
              <FormattedMessage id="download.post-title" />
            </p>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto mt-8 px-4 sm:px-6  lg:px-8 ">
        <div className="bg-white overflow-hidden shadow rounded-lg border">
          <div className="px-4 py-5 sm:px-6">
            <div className="">
              <h2 className="font-bold text-2xl leading-7">
                <FormattedMessage
                  id="download.latest-version"
                  values={{ version: data.latest.frontmatter.version }}
                />
                <br />
                <span className="text-gray-600 text-base font-bold">
                  <FormattedDate value={data.latest.frontmatter.date} />
                  &nbsp; &#183;&nbsp;
                  <Link
                    className="text-gray-500 hover:text-gray-900 font-medium text-base"
                    to={data.releaseNoteLink.path}
                  >
                    <FormattedMessage id="download.release-note" />
                  </Link>
                  &nbsp; &#183;&nbsp;
                  <MacOsVersions />
                </span>
              </h2>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:p-6">
            
            <Button
              onClick={() => showModal(true)}
              color="pink"
              size="big"
              inverted
              className="m-auto flex text-base leading-6  md:text-lg font-medium "
            >
              <svg
                className="-ml-1 mr-3 h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
              <FormattedMessage
                id="download.exe-button.text"
                values={{
                  size: data.binarySizeExe.sizeText,
                }}
              />
            </Button>

            {/* <div className="h-2"> </div> */}
          </div>
        </div>
      </div>
      <SubscribeModal
        to={data.downloadingLink.path}
        isOpen={isModal}
        onClose={() => showModal(false)}
      />
    </Layout>
  );
};

export default DownloadPage;

export const query = graphql`
  query downloadPageQuery($language: String!) {
    imageWindows10: file(relativePath: { eq: "pages/download/windows10.png" }) {
      publicURL
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    releaseNoteLink: sitePage(
      context: { tags: { eq: "latest" }, language: { eq: $language } }
    ) {
      path
    }

    latest: markdownRemark(frontmatter: { tags: { eq: "latest" } }) {
      frontmatter {
        version
        date
      }
    }

    downloadingLink: sitePage(
      context: { id: { eq: "downloading" }, language: { eq: $language } }
    ) {
      path
    }

    binarySizeExe: screenpoloDownloadLink(name: { eq: "exe" }) {
      sizeText
    }

    binarySizeMsi: screenpoloDownloadLink(name: { eq: "msi" }) {
      sizeText
    }

    binarySizes: site {
      siteMetadata {
        downloadLinks {
          exe {
            size {
              text
            }
          }
        }
      }
    }
  }
`;
